
    import TablePerformanceLimbo from "@/components/tables/table-performance-limbo.vue";
    import TableSettings from "@/components/tables/table-settings.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            TablePerformanceLimbo,
            TableSettings,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {
        private readonly statistics = {
            // tslint:disable
            headers: [
                { text: 'Statistic',    value: 'statistic',     align: 'left',  sortable:   false },
                { text: 'Description',  value: 'description',   sortable:   false },
            ],

            content: [
                {
                    statistic:       'APP T',
                    description:     'App GPU Time: The amount of time the application spends rendering a single frame (in microseconds, 1/1000th of a millisecond).',
                },
                {
                    statistic:       'GPU L',
                    description:     'GPU Level 0 to 4: Although this level can be set manually, use of dynamic clock throttling, which increases this if the app is not hitting frame rate at a requested level, is recommended.',
                },
                {
                    statistic:       'GPU U',
                    description:     'GPU Utilization %: If this value is maxed 100%, the app is GPU bound.',
                },
                {
                    statistic:       'SSAA',
                    description:     'Super Sampled Anti Aliasing: The scale of the eye buffers in relation to the physical display.',
                },
            ],
            // tslint:enable
        };

        private readonly session05022022 = {
            // tslint:disable
            headers: [
                { text: 'Session',          value: 'session',       sortable:   true },
                { text: 'App Time',         value: 'appT',          sortable:   false },

                { text: 'CPU Lvl',          value: 'cpuL',          sortable:   false },

                { text: 'Fvtd Lvl',         value: 'fovL',          sortable:   false },

                { text: 'GPU Lvl',          value: 'gpuL',          sortable:   false },
                { text: 'GPU Use',          value: 'gpuU',          sortable:   false },

                { text: 'Ground',           value: 'groundIcon',    sortable:   false },
                { text: 'Long',             value: 'longIcon',      sortable:   false },
                { text: 'Med',              value: 'mediumIcon',    sortable:   false },
                { text: 'Short',            value: 'shortIcon',     sortable:   false },

                { text: 'Main Menu',        value: 'mainMenuIcon',  sortable:   false },

                { text: 'Lights',           value: 'lightsIcon',    sortable:   false },

                { text: 'SSAA',             value: 'ssaa',          sortable:   false },
                { text: 'MSAA',             value: 'msaa',          sortable:   false },

                { text: 'Notes',            value: 'notes',         sortable:   false },
            ],

            scene: [
                {
                    session:        'Quest2',
                    appT:           '5970',

                    cpuL:           '0',

                    fovL:           '3',

                    gpuL:           '4',
                    gpuU:           '94%',

                    groundIcon:     'check',
                    longIcon:       'check',
                    mediumIcon:     'check',
                    shortIcon:      'check',

                    mainMenuIcon:   'not_interested',

                    lightsIcon:     'check',

                    ssaa:           '1.255x',
                    msaa:           '4x',

                    notes:          'Main Menu is off'
                },
            ],
            // tslint:enable
        };

        private readonly session18042021 = {
            // tslint:disable
            headers: [
                {
                    text:       '#',
                    align:      'left',
                    sortable:   true,
                    value:      'session',
                },
                { text: 'App Time',       value: 'appTime',       sortable:   false },

                { text: 'FOV Level',      value: 'fov',           sortable:   false },
                { text: 'GPU Level',      value: 'gpuL',          sortable:   false },
                { text: 'GPU Usage',      value: 'gpuU',          sortable:   false },

                { text: 'Sky',            value: 'sky',           sortable:   false },
                { text: 'Ground',         value: 'ground',        sortable:   false },
                { text: 'Long',           value: 'long',          sortable:   false },
                { text: 'Medium',         value: 'medium',        sortable:   false },
                { text: 'Short',          value: 'short',         sortable:   false },
                { text: 'Main Menu',      value: 'mainMenu',    sortable:   false },
                { text: 'Lights',         value: 'lights',        sortable:   false },

                { text: 'SSAA',           value: 'ssaa',          sortable:   false },

                { text: 'Notes',          value: 'notes',         sortable:   false },
            ],

            scene: [
                {
                    session:       'Quest2',

                    fov:           '0',
                    gpuL:          '3',
                    gpuU:          '75%',
                    appTime:       '7500',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    ssaa:          '1.255',

                    notes:         '2x Anisotripic bilinear on Diffuse & Lightmaps.',
                },
                {
                    session:       'Quest1',

                    fov:           '0',
                    gpuL:          '4',
                    gpuU:          '80%',
                    appTime:       '11800',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    ssaa:          '1.255',

                    notes:         '2x Anisotripic bilinear on Diffuse & Lightmaps.',
                },
            ],
            // tslint:enable
        };

        private readonly session06042021 = {
            // tslint:disable
            headers: [
                {
                    text:       '#',
                    align:      'left',
                    sortable:   true,
                    value:      'session',
                },
                { text: 'App Time',       value: 'appTime',       sortable:   false },

                { text: 'FOV Level', value: 'fov',           sortable:   false },
                { text: 'GPU Level',      value: 'gpuL',          sortable:   false },
                { text: 'GPU Usage',      value: 'gpuU',          sortable:   false },

                { text: 'Sky',            value: 'sky',           sortable:   false },
                { text: 'Ground',         value: 'ground',        sortable:   false },
                { text: 'Long',           value: 'long',          sortable:   false },
                { text: 'Medium',         value: 'medium',        sortable:   false },
                { text: 'Short',          value: 'short',         sortable:   false },
                { text: 'Main Menu',      value: 'mainMenu',    sortable:   false },
                { text: 'Lights',         value: 'lights',        sortable:   false },

                { text: 'SSAA',           value: 'ssaa',          sortable:   false },

                { text: 'Notes',          value: 'notes',         sortable:   false },
            ],

            scene: [
                {
                    session:       'Quest2',

                    fov:           '0',
                    gpuL:          '3',
                    gpuU:          '75%',
                    appTime:       '7500',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    ssaa:          '1.255',

                    notes:         '2x Anisotripic bilinear on Diffuse & Lightmaps.',
                },
            ],
            // tslint:enable
        };

        private readonly session23032021 = {
            // tslint:disable
            headers: [
                {
                    text:       '#',
                    align:      'left',
                    sortable:   true,
                    value:      'session',
                },
                { text: 'App Time',       value: 'appTime',       sortable:   false },

                { text: 'FOV Level', value: 'fov',           sortable:   false },
                { text: 'GPU Level',      value: 'gpuL',          sortable:   false },
                { text: 'GPU Usage',      value: 'gpuU',          sortable:   false },

                { text: 'Sky',            value: 'sky',           sortable:   false },
                { text: 'Ground',         value: 'ground',        sortable:   false },
                { text: 'Long',           value: 'long',          sortable:   false },
                { text: 'Medium',         value: 'medium',        sortable:   false },
                { text: 'Short',          value: 'short',         sortable:   false },
                { text: 'Main Menu',      value: 'mainMenu',    sortable:   false },
                { text: 'Lights',         value: 'lights',        sortable:   false },

                { text: 'SSAA',           value: 'ssaa',          sortable:   false },

                { text: 'Notes',          value: 'notes',         sortable:   false },
            ],

            scene: [
                {
                    session:       'Quest2',

                    fov:           '0',
                    gpuL:          '3',
                    gpuU:          '75%',
                    appTime:       '7500',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    ssaa:          '1.255',

                    notes:         '2x Anisotripic bilinear on Diffuse & Lightmaps.',
                },
            ],
            // tslint:enable
        };

        private readonly session28112020 = {
            // tslint:disable
            headers: [
                {
                    text:       '#',
                    align:      'left',
                    sortable:   true,
                    value:      'session',
                },
                { text: 'App Time',       value: 'appTime',       sortable:   false },

                { text: 'FOV Level', value: 'fov',           sortable:   false },
                { text: 'GPU Level',      value: 'gpuL',          sortable:   false },
                { text: 'GPU Usage',      value: 'gpuU',          sortable:   false },

                { text: 'Sky',            value: 'sky',           sortable:   false },
                { text: 'Ground',         value: 'ground',        sortable:   false },
                { text: 'Long',           value: 'long',          sortable:   false },
                { text: 'Medium',         value: 'medium',        sortable:   false },
                { text: 'Short',          value: 'short',         sortable:   false },
                { text: 'Main Menu',      value: 'mainMenu',    sortable:   false },
                { text: 'Lights',         value: 'lights',        sortable:   false },

                { text: 'SSAA',           value: 'ssaa',          sortable:   false },

                { text: 'Notes',          value: 'notes',         sortable:   false },
            ],

            scene: [
                {
                    session:       'Quest1 01',
                    appTime:       '1600',
                    gpuL:          '4',
                    gpuU:          '25%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'No',
                    ground:        'No',
                    long:          'No',
                    medium:        'No',
                    short:         'No',
                    mainMenu:    'No',                    
                    lights:        'No',
                },
                {
                    session:       'Quest1 02',
                    appTime:       '3800',
                    gpuL:          '4',
                    gpuU:          '39%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'No',
                    long:          'No',
                    medium:        'No',
                    short:         'No',
                    mainMenu:    'No',                    
                    lights:        'No',
                },
                {
                    session:       'Quest1 03',
                    appTime:       '5300',
                    gpuL:          '4',
                    gpuU:          '43%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'No',
                    medium:        'No',
                    short:         'No',
                    mainMenu:    'No',
                    lights:        'No',
                },
                {
                    session:       'Quest1 04',
                    appTime:       '5500',
                    gpuL:          '4',
                    gpuU:          '45%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'No',
                    short:         'No',
                    mainMenu:    'No',
                    lights:        'No',
                },
                {
                    session:       'Quest1 05',
                    appTime:       '7600',
                    gpuL:          '4',
                    gpuU:          '53%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'No',
                    mainMenu:    'No',
                    lights:        'No',
                },
                {
                    session:       'Quest1 06',
                    appTime:       '9500',
                    gpuL:          '4',
                    gpuU:          '75%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'No',
                    lights:        'No',
                },
                {
                    session:       'Quest1 07',
                    appTime:       '12800',
                    gpuL:          '4',
                    gpuU:          '80%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'No',
                },
                {
                    session:       'Quest1 08',
                    appTime:       '12800',
                    gpuL:          '4',
                    gpuU:          '80%',
                    fov:           '0',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',
                },
                {
                    session:       'Quest1 09',
                    appTime:       '12300',
                    gpuL:          '4',
                    gpuU:          '76%',
                    fov:           '4',
                    ssaa:          'x1.5',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    notes:         'Forced FOV',
                },
                {
                    session:       'Quest1 10',
                    appTime:       '11200',
                    gpuL:          '4',
                    gpuU:          '74%',
                    fov:           '0',
                    ssaa:          'x1.4',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    notes:         'Less SSAA',
                },
                {
                    session:       'Quest1 11',
                    appTime:       '9600',
                    gpuL:          '4',
                    gpuU:          '72%',
                    fov:           '0',
                    ssaa:          'x1.25',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    notes:         'Even less SSAA',
                },
                {
                    session:       'Quest1 12',
                    appTime:       '6500',
                    gpuL:          '4',
                    gpuU:          '52%',
                    fov:           '0',
                    ssaa:          'x1.0',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    notes:         'No SSAA',
                },
                {
                    session:       'Quest1 13',
                    appTime:       '10300',
                    gpuL:          '4',
                    gpuU:          '72%',
                    fov:           '0',
                    ssaa:          'x1.25',

                    sky:           'Yes',
                    ground:        'Yes',
                    long:          'Yes',
                    medium:        'Yes',
                    short:         'Yes',
                    mainMenu:    'Yes',
                    lights:        'Yes',

                    notes:         '2x Anisotripic bilinear on Diffuse & Lightmaps.',
                },
            ],
            // tslint:enable
        };

        // Data function
        private data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                unityIconPathAndName:
                    require("@/assets/images/unity.png"),
            };
        }
    }
